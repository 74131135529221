<template>
    <div class="audio-player-container">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-1">
                <span class="card-title mb-0 text-truncate" style="max-width: 170px; display: inline-block">
                {{ title }}
                </span>
            
          </div>
          <div class="d-flex justify-content-between align-items-center mb-1">
 
            <span class="badge bg-primary">{{ currentTimeFormatted }}/{{ durationFormatted }}</span>
          </div>
 
          <div class="progress-container mb-2">
          <progress 
            :value="currentTime" 
            :max="duration" 
            @click.stop="seekAudio"
            style="width: 100%;"
          ></progress>
        </div>
          
    
          <div class="d-flex justify-content-center align-items-center relative">
          
              
              <button 
                class="btn-outline-primary" 
                @click.stop="togglePlay"
              >
                <i :class="[isPlaying ? 'bi-pause' : 'bi-play']" style="font-size: 1.3rem !important;"></i>
              </button>
             
              <div class="d-flex align-items-center absolute" style="position: absolute; right: 5%;">
                <button 
                  class="btn-outline-primary volume-control"
                  @click.stop="toggleMute"
                >
                  <i :class="[isMuted ? 'bi-volume-mute' : volume > 0.5 ? 'bi-volume-up' : 'bi-volume-down']" class=""  style="font-size: 1rem !important;"></i>
                </button>
                 
              </div> 
 
            <div>
              
            </div>
          </div>
        </div>
      </div>
      

      <audio 
        ref="audioElement"
        :src="src"
        @timeupdate="updateTime"
        @loadedmetadata="updateDuration"
        @ended="onAudioEnded"
      ></audio>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AudioPlayer',
    props: {
      src: {
        type: String,
        required: true
      },
      title: {
        type: String,
        default: 'Audio Player'
      },
     
    },
    data() {
      return {
        isPlaying: false,
        isMuted: false,
        currentTime: 0,
        duration: 0,
        volume: 0.7,
        savedVolume: 0.7,
        playbackRate: 1.0,
        playbackSpeeds: [0.5, 0.75, 1.0, 1.25, 1.5, 2.0]
      }
    },
    computed: {
      progress() {
        return (this.currentTime / this.duration) * 100 || 0
      },
      currentTimeFormatted() {
        return this.formatTime(this.currentTime)
      },
      durationFormatted() {
        return this.formatTime(this.duration)
      }
    },
    watch: {
      volume(newVolume) {
        this.$refs.audioElement.volume = newVolume
        if (newVolume > 0 && this.isMuted) {
          this.isMuted = false
        }
      },
      playbackRate(newRate) {
        this.$refs.audioElement.playbackRate = newRate
      }
    },
    mounted() {
      this.$refs.audioElement.volume = this.volume
    },
    created() {

  },
    methods: {
      togglePlay() {
        if (this.isPlaying) {
          this.$refs.audioElement.pause()
        } else {
          this.$refs.audioElement.play()
        }
        this.isPlaying = !this.isPlaying
      },
      toggleMute() {
        if (this.isMuted) {
          this.$refs.audioElement.volume = this.savedVolume
          this.volume = this.savedVolume
        } else {
          this.savedVolume = this.volume
          this.$refs.audioElement.volume = 0
          this.volume = 0
        }
        this.isMuted = !this.isMuted
      },
      updateTime() {
        this.currentTime = this.$refs.audioElement.currentTime
      },
      updateDuration() {
        this.duration = this.$refs.audioElement.duration
      },
      onAudioEnded() {
        this.isPlaying = false
        this.currentTime = 0
      },
      seekAudio(event) { 
        const progressBar = event.target
        const clickPosition = event.offsetX
        const progressBarWidth = progressBar.clientWidth
        const seekPercentage = clickPosition / progressBarWidth
        this.$refs.audioElement.currentTime = seekPercentage * this.duration
      },
      changeSpeed(speed) {
        this.playbackRate = speed
      },
      downloadAudio() {
        if (this.downloadUrl) {
          const link = document.createElement('a')
          link.href = this.downloadUrl
          link.download = this.title || 'audio'
          link.click()
        }
      },
      toggleFullscreen() {
        // console.log('Fullscreen toggled')
      },
      formatTime(seconds) {
        if (isNaN(seconds)) return '00:00'
        
        const minutes = Math.floor(seconds / 60)
        const secs = Math.floor(seconds % 60)
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
      }
    }
  }
  </script>
  
  <style scoped>
  .audio-player-container {
    max-width: 200px;
    width: 195px;
    min-width: 195px;
    margin: 0 auto;
    height: 170px;
    max-height: 170px;
  }
  
  .card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .progress {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
  }
  
  .progress-bar {
    transition: width 0.1s linear;
  }
  
  .btn-outline-primary, .btn-outline-secondary, .btn-outline-success {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .btn-sm {
    width: 32px;
    height: 32px;
  }
  
  .bi {
    font-size: 1.2rem;
  }

  .badge { 
    font-size: 60% !important; 
}
  
  .volume-control {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
  }
  
  .card .card-title { 
    font-size: 0.8rem !important;
}
.progress-container {
  width: 100%;
}

[dir] .card-body {
    padding: 1.0rem;
}
 
 
  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
  </style>